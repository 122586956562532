<template>
  <div class="productionEquipment dataM">
    <el-table 
      :data="tableList" 
      header-cell-class-name="tableHeader" 
      style="width: 100%" 
      @selection-change="handleSelectionChange"
      @row-dblclick="handleEdit"
    >
      <el-table-column type="selection" width="55"/>
      <el-table-column label="序号" type="index" :index="getIndex" width="100"/>
      <el-table-column prop="partCode" label="状态">
         <template slot="header">
            <el-dropdown>
              <span class="el-dropdown-link">
                状态<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="changeStatus(0)">全部</el-dropdown-item>
                <el-dropdown-item @click.native="changeStatus(1)">正常</el-dropdown-item>
                <el-dropdown-item @click.native="changeStatus(2)">故障</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
         </template>
        <template slot-scope="scope">
          <span v-if="scope.row.equipmentStatus == 1"  class="greenStatus">正常</span>
          <span  class="redStatus" v-else>故障</span>
        </template>
      </el-table-column>
      <el-table-column prop="equipmentNo" label="设备编号"/>
      <el-table-column prop="equipmentName" label="设备名称"/>
      <el-table-column prop="equipmentParameters" label="设备型号"/>
      <el-table-column prop="category" label="属性"/>
      <el-table-column prop="receiveOrderType" label="接单方式">
        <template slot-scope="scope">
          <span>{{scope.row.receiveOrderType == 2 ? '散件' :(scope.row.receiveOrderType == 1 ? '正常':'')}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="workshiftSchemeName" label="日历方案"/>
      <el-table-column prop="robotName" label="机器人配置"/>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <div class="operation">
            <el-tooltip v-premiSub="'生产设备_编辑'" effect="dark" content="编辑" placement="top">
              <img src="@/assets/images/dataManage/edit.png" style="margin-right:20px" @click="handleEdit(scope.row)"/>
            </el-tooltip>
            <el-tooltip v-premiSub="'生产设备_删除'" effect="dark" content="删除" placement="top">
              <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
            </el-tooltip>
            <!-- <span style="margin:10px">|</span>
            <span style="color: #2DEB79;cursor:pointer" @click="seePlan(scope.row)">保养计划</span> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <planDialog :dialogVisible.sync="dialogVisible" :planData.sync="planData"/>
    <productionEquipmentDialog :createVisible.sync="createVisible" :currentData="currentData" @handleSearch="handleSearch"/>
  </div>
</template>

<script>
const planDialog = () => import('../dialog/planDialog.vue')
const productionEquipmentDialog = () => import('../dialog/productionEquipmentDialog.vue')
export default {
  name:"productionEquipment",
  components:{
    planDialog,
    productionEquipmentDialog
  },
  props:{
    tableList:{
      require:true,
      default:[]
    },
    statusType:{
      require:true
    },
    page:{
      default:1
    },
    size:{
      default:10
    }
  },
  data(){
    return{
      dialogVisible:false,
      planData:{},
      createVisible:false,
      currentData:{},
    }
  },
  methods:{
    getIndex(index) {
      const result = (this.page- 1) * this.size + index + 1
      return result < 10 ? `0${result}` : result
    },
    handleSelectionChange(val){
      let multipleSelection = val.map(row => row.id);
      this.$emit('update:multipleSelection',multipleSelection);
    },
    comfirm(row){
      this.$emit('comfirm',row)
    },
    seePlan(row){
      this.planData = row
      this.dialogVisible = true
    },
    changeStatus(val){
      this.$emit('update:statusType',val)
    },
    handleEdit(row){
      // this.currentData = row
      // this.createVisible = true
      this.$router.push({
        path:'editproduceResource',
        query:{
          id:row.id
        }
      })
    },
    handleSearch(){
      this.$emit('handleSearch')
    }
  }
}
</script>

<style lang="scss">
.productionEquipment{
  .operation{
    font-size: 16px;
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      margin-left: 10px;
    }
  }
  img{
    // margin: 0 15px;
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
}
</style>